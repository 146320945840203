import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SearchService } from '../shared/services/entitySearch/search.service';
import { AuthService } from '../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PopupComponent } from '../shared/popup/popup.component';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-entity-by-id',
  templateUrl: './search-entity-by-id.component.html',
  styleUrls: ['./search-entity-by-id.component.scss']
})
export class SearchEntityByIdComponent implements OnInit {
  professionalInPersonArr = [];
  personMap = {};
  entityDetails = [];
  anshDetails = [];
  anshDetailsFew = [];
  entityObject: any;
  entityData: any;
  urlString: any;
  result_name: any;
  actualUrl: any;
  api = environment.domain;
  url = window.location.href;
  e1: number = 1;
  config: any;
  IndustryDetails = false;
  isLoading = false;
  userIsAuthenticated = false;
  premiumNoValue: any;
  profile: any;

  startdateStr: any;
  titleStr: any;
  personurlStr: any;
  positionurlStr: any;
  positionArr = [];
  positionurlArr = [];
  positionRank = [];
  positionStr: any;
  postionRank1: any;
  nameStr: any;
  nameStr1: any;
  nameStr2: any;
  professionalInOrgArr = [];

  private authListnerSub: Subscription;
  constructor(private route: ActivatedRoute,
    private http: HttpClient, private entityDetail: SearchService,
    private auth: AuthService, private router: Router,
    public dialog: MatDialog, private title: Title) {
    this.config = {
      itemsPerPage: 8,
      currentPage: 1,
      totalItems: this.entityDetails.length
    };
  }

  ngOnInit() {
    this.IndustryDetails = false;
    //this.auth.getTempToken();
    this.userIsAuthenticated = this.auth.getAuthStatus();
    this.authListnerSub = this.auth.getAuthListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
      if (this.userIsAuthenticated) {
        this.profile = this.auth.getAuthToken();
        console.log("on init:: login check::",this.profile);
        console.log("token check::",this.auth.getAuthToken());
      }
    })

    if (this.userIsAuthenticated) {
      this.profile = this.auth.getAuthToken();
       console.log("Userdetails..if", this.auth.getAuthToken());
    }
    this.premiumNoValue = this.userIsAuthenticated ? 'N/A' : 'Login Required';
    this.route.params.subscribe((param: Params) => {
      let entityId: string = param['id'];
      this.actualUrl = this.api + '/' + entityId;
      if (entityId == param['id']) {
        // console.log(entityId);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
      }
      if (entityId.indexOf("-") == -1) {
        this.router.navigate(['/']);
      }
      this.router.onSameUrlNavigation = 'reload';
      this.isLoading = true;
      this.entityData = entityId+"?_=4479567241%22";
      this.entityDetail.getEntityDetail(this.entityData, this.userIsAuthenticated).subscribe((response) => {
        this.isLoading = false;
        this.entityObject = response;                
        if(this.entityObject['entityType'][0] === 'Organization'){
          this.title.setTitle(this.entityObject['Organization Name'] + ' | PermID');
        }
        else if(this.entityObject['entityType'][0] === 'Instrument') {
          this.title.setTitle(this.entityObject['Instrument Name'] + ' | PermID');
        } 
        else if(this.entityObject['entityType'][0] === 'Quote') {
          this.title.setTitle(this.entityObject['Quote Name'] + ' | PermID');
        } 
        else if(this.entityObject['entityType'][0] === 'AssetClass' || this.entityObject['entityType'][0] ==='IndustryGroup' || this.entityObject['entityType'][0] ==='BusinessSector' || this.entityObject['entityType'][0] ==='EconomicSector' ||
        this.entityObject['entityType'][0] === 'Industry' || this.entityObject['entityType'] === 'Activity') {
          this.title.setTitle(this.entityObject['Label'][0] + ' | PermID');
        } 
        else if (this.entityObject['entityType'][0] === 'Currency'){
          this.title.setTitle(this.entityObject['prefLabel'] + ' | PermID');
        }
        else {
          this.title.setTitle(this.entityObject['Position Type'] + ' | PermID');
        }
        for (let key in this.entityObject['Additional Info']) {
          if (this.entityObject['Additional Info'].hasOwnProperty(key)) {
            this.entityDetails.push(this.entityObject['Additional Info'][key]);
          }
        }

        for (let keyProf in this.entityDetails) {
          if (this.entityDetails.hasOwnProperty(keyProf)) {
            this.entityDetails[keyProf]["Position Rank"].sort((a, b) => {
              if (a["Position Rank"] == undefined || a["Position Rank"][0] == undefined) return 1;
              if (b["Position Rank"] == undefined || b["Position Rank"][0] == undefined) return -1;
              return a["Position Rank"][0] - b["Position Rank"][0];
            });
            this.professionalInOrgArr.push(this.entityDetails[keyProf]);
          }
        }

        this.professionalInOrgArr.sort((a, b) => {
          if (a["Position Rank"] == undefined || a["Position Rank"][0] == undefined) return 1;
          if (b["Position Rank"] == undefined || b["Position Rank"][0] == undefined) return -1;
          return a["Position Rank"][0] - b["Position Rank"][0];
        });

        for (let i = 0; i < this.professionalInOrgArr.length; i++) {
          this.startdateStr = 'N/A';;
          this.titleStr = 'N/A';
          this.personurlStr = this.professionalInOrgArr[i]['Person URL'][0].split('-').pop();
          this.positionurlStr = this.professionalInOrgArr[i]['Position URL'][0].split('-').pop();
          this.positionStr = this.professionalInOrgArr[i]['Position Type'][0];
          this.postionRank1 = this.professionalInOrgArr[i]['Position Rank'][0];

          if (this.professionalInOrgArr[i]['Position Start Date'] != undefined) {
            this.startdateStr = this.professionalInOrgArr[i]['Position Start Date'];
          }
          if (this.professionalInOrgArr[i]['hasReportedTitle.person-permid'] != undefined) {
            this.titleStr = this.professionalInOrgArr[i]['hasReportedTitle.person-permid'];
          }
          if (this.professionalInOrgArr[i]['Middle name'] != undefined && this.professionalInOrgArr[i]['Preferred name'] == undefined && this.professionalInOrgArr[i]['Honorific suffix'] == undefined) {
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + this.professionalInOrgArr[i]['Middle name'] + ' ' + this.professionalInOrgArr[i]['Family name'];
          }
          else if (this.professionalInOrgArr[i]['Preferred name'] != undefined && this.professionalInOrgArr[i]['Middle name'] != undefined && this.professionalInOrgArr[i]['Honorific suffix'] == undefined) {
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + '(' + this.professionalInOrgArr[i]['Preferred name'] + ')' + ' ' + this.professionalInOrgArr[i]['Middle name'] + ' ' + this.professionalInOrgArr[i]['Family name'];
          }
          else if (this.professionalInOrgArr[i]['Preferred name'] != undefined && this.professionalInOrgArr[i]['Middle name'] == undefined) {
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + '(' + this.professionalInOrgArr[i]['Preferred name'] + ')' + ' ' + this.professionalInOrgArr[i]['Family name'];
          }
          else if (this.professionalInOrgArr[i]['Middle name'] != undefined && this.professionalInOrgArr[i]['Honorific suffix'] != undefined && this.professionalInOrgArr[i]['Preferred name'] == undefined){
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + this.professionalInOrgArr[i]['Middle name'] + ' ' + this.professionalInOrgArr[i]['Family name'] + ' ' + this.professionalInOrgArr[i]['Honorific suffix'];
          }
          else if (this.professionalInOrgArr[i]['Middle name'] != undefined && this.professionalInOrgArr[i]['Honorific suffix'] != undefined && this.professionalInOrgArr[i]['Preferred name'] != undefined){
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + '(' + this.professionalInOrgArr[i]['Preferred name'] + ')' + ' '  + this.professionalInOrgArr[i]['Middle name'] + ' ' + this.professionalInOrgArr[i]['Family name'] + ' ' + this.professionalInOrgArr[i]['Honorific suffix'];
          }
          else if(this.professionalInOrgArr[i]['Given name'] != undefined && this.professionalInOrgArr[i]['Family name'] !=undefined && this.professionalInOrgArr[i]['Honorific suffix'] != undefined){
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + this.professionalInOrgArr[i]['Family name'] + ' ' + this.professionalInOrgArr[i]['Honorific suffix'];
          }
          else {
            this.nameStr = this.professionalInOrgArr[i]['Given name'] + ' ' + this.professionalInOrgArr[i]['Family name'];
          }

          let k = 0;
          this.positionArr = [];
          this.positionurlArr = [];
          this.positionRank = [];

          for (let j = 0; j < this.professionalInOrgArr.length; j++) {

            if (this.professionalInOrgArr[j]['Middle name'] != undefined && this.professionalInOrgArr[j]['Preferred name'] == undefined && this.professionalInOrgArr[j]['Honorific suffix'] == undefined) {
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + this.professionalInOrgArr[j]['Middle name'] + ' ' + this.professionalInOrgArr[j]['Family name'];
            }
            else if (this.professionalInOrgArr[j]['Preferred name'] != undefined && this.professionalInOrgArr[j]['Middle name'] != undefined && this.professionalInOrgArr[i]['Honorific suffix'] == undefined) {
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + '(' + this.professionalInOrgArr[j]['Preferred name'] + ')' + ' ' + this.professionalInOrgArr[j]['Middle name'] + ' ' + this.professionalInOrgArr[j]['Family name'];
            }
            else if (this.professionalInOrgArr[j]['Preferred name'] != undefined && this.professionalInOrgArr[j]['Middle name'] == undefined) {
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + '(' + this.professionalInOrgArr[j]['Preferred name'] + ')' + ' ' + this.professionalInOrgArr[j]['Family name'];
            }
            else if (this.professionalInOrgArr[j]['Middle name'] != undefined && this.professionalInOrgArr[j]['Honorific suffix'] != undefined && this.professionalInOrgArr[j]['Preferred name'] == undefined){
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + this.professionalInOrgArr[j]['Middle name'] + ' ' + this.professionalInOrgArr[j]['Family name'] + ' ' + this.professionalInOrgArr[j]['Honorific suffix'];
            }
            else if (this.professionalInOrgArr[j]['Middle name'] != undefined && this.professionalInOrgArr[j]['Honorific suffix'] != undefined && this.professionalInOrgArr[j]['Preferred name'] != undefined){
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + '(' + this.professionalInOrgArr[j]['Preferred name'] + ')' + ' '  + this.professionalInOrgArr[j]['Middle name'] + ' ' + this.professionalInOrgArr[j]['Family name'] + ' ' + this.professionalInOrgArr[j]['Honorific suffix'];
            }
            else if(this.professionalInOrgArr[j]['Given name'] != undefined && this.professionalInOrgArr[j]['Family name'] !=undefined && this.professionalInOrgArr[j]['Honorific suffix'] != undefined){
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + this.professionalInOrgArr[j]['Family name'] + ' ' + this.professionalInOrgArr[j]['Honorific suffix'];
            }
            else {
              this.nameStr1 = this.professionalInOrgArr[j]['Given name'] + ' ' + this.professionalInOrgArr[j]['Family name'];
            }

            if (this.nameStr1.replace(/\s/g, '').toLowerCase() == this.nameStr.replace(/\s/g, '').toLowerCase()) {
              this.positionRank[k] = this.professionalInOrgArr[j]["Position Rank"];
              this.positionArr[k] = this.professionalInOrgArr[j]['Position Type'][0];
              this.positionurlArr[k] = this.professionalInOrgArr[j]['Position URL'][0].split('-').pop();
              k = k + 1;
            }
          } // for (let j = 0; j < this.entityDetails.length; j++)

          if (k == 0) {
            this.positionArr[k] = this.positionStr;
            this.positionurlArr[k] = this.positionurlStr;
          }
          if (this.professionalInOrgArr[i]['Given name'] != undefined) {
            this.anshDetails.push({ 'name': this.nameStr, 'personurl': this.personurlStr, 'positionurl': this.positionurlArr, 'position': this.positionArr, 'positionrank': this.positionRank, 'title': this.titleStr, 'startdate': this.startdateStr, 'removeonly': 'N' });
          }
          else {
            // console.log("Ignoring :", this.entityDetails[i]);
          }
        } //(let i = 0; i < this.entityDetails.length; i++)
        //-------------------------------------------------------------------------------------------------//

        for (let i = 0; i < this.anshDetails.length; i++) {
          this.nameStr1 = this.anshDetails[i].name.replace(/\s/g, '').toLowerCase();
          if (this.anshDetails[i].removeonly == 'N') {
            this.anshDetailsFew.push({ 'name': this.anshDetails[i].name, 'personurl': this.anshDetails[i].personurl, 'positionurl': this.anshDetails[i].positionurl, 'position': this.anshDetails[i].position, 'positionrank': this.anshDetails[i].positionrank, 'title': this.anshDetails[i].title, 'startdate': this.anshDetails[i].startdate, 'removeonly': 'N' });
          }
          for (let j = 0; j < this.anshDetails.length; j++) {
            this.nameStr2 = this.anshDetails[j].name.replace(/\s/g, '').toLowerCase();
            if (this.nameStr2 == this.nameStr1 && j != i) {
              this.anshDetails[j].removeonly = 'Y';
            }
          }
        } // (let i = 0; i < this.anshDetails.length; i++)
        //-------------------------------------------------------------------------------------------------//
        if (this.entityObject['holdsPosition.person-permid']) {
          for (let i = 0; i < this.entityObject['holdsPosition.person-permid'].length; i++) {
            if (this.entityObject['holdsPosition.person-permid'][i]['entityType'] == 'Officership' || this.entityObject['holdsPosition.person-permid'][i]['entityType'] == 'Directorship') {

              var str = this.entityObject['holdsPosition.person-permid'][i]['Title'] + i;
              if (this.entityObject['holdsPosition.person-permid'][i]['Title'] && this.entityObject['holdsPosition.person-permid'][i]['Position Start Date'] && this.entityObject['holdsPosition.person-permid'][i]['isPositionIn.person-permid'] && this.entityObject['holdsPosition.person-permid'][i]['isPositionIn.person-permid'][0]['Organization Name']) {
                str = this.entityObject['holdsPosition.person-permid'][i]['Title'][0] + this.entityObject['holdsPosition.person-permid'][i]['Position Start Date'][0] + '-'
                str += this.entityObject['holdsPosition.person-permid'][i]['Position End Date'] ? this.entityObject['holdsPosition.person-permid'][i]['Position End Date'][0] : ''
                str += '-' + this.entityObject['holdsPosition.person-permid'][i]['isPositionIn.person-permid'][0]['Organization Name'][0]
              }

              if (this.personMap[str] == undefined)
                this.personMap[str] = [this.entityObject['holdsPosition.person-permid'][i]]
              else
                this.personMap[str].push(this.entityObject['holdsPosition.person-permid'][i])
            }
          }
          for (let key in this.personMap) {
            if (this.personMap.hasOwnProperty(key)) {
              this.professionalInPersonArr.push(this.personMap[key]);
            }
          }
          // console.log("professionalInPersonArr", this.professionalInPersonArr);
        }

        if (this.entityObject['entityType'][0] === 'Person') {
          this.entityObject.entityFullName = '';
          if (this.entityObject['Given name'] && this.entityObject['Given name'].length > 0)
            this.entityObject.entityFullName += ' ' + this.entityObject['Given name'][0];
          if (this.entityObject['Preferred name'] && this.entityObject['Preferred name'].length > 0)
            this.entityObject.entityFullName += ' (' + this.entityObject['Preferred name'][0] + ')';
          if (this.entityObject['Middle name'] && this.entityObject['Middle name'].length > 0)
            this.entityObject.entityFullName += ' ' + this.entityObject['Middle name'][0];
          if (this.entityObject['Family name'] && this.entityObject['Family name'].length > 0)
            this.entityObject.entityFullName += ' ' + this.entityObject['Family name'][0];
          if (this.entityObject['Honorific suffix'] && this.entityObject['Honorific suffix'].length > 0)
            this.entityObject.entityFullName += ' ' + this.entityObject['Honorific suffix'][0];
          this.result_name = this.entityObject.entityFullName;          
          this.title.setTitle(this.result_name + ' | PermID');
        }

      }, error => {
        // console.log(error);
        //window.location.reload();
        // this.router.navigate(['/']);
      })
    })
  }

	primaryindustry(primaryindustryID){
    this.entityDetail.getEntityDetail(primaryindustryID, this.userIsAuthenticated).subscribe((response) => {
      this.isLoading = false;
      this.IndustryDetails = true;
      this.entityObject = response;
    },error => {
      // console.log("error::",error);
    })
  }
  
  getApiResponse(headerText, formatStr) {
    let params, paramQuery, queryParam;
    if (!this.entityObject['PERM ID']) {
      queryParam = this.url.split('?')[0].split('/').pop();
    }
    else if (this.entityObject['PERM ID'] == undefined) {
      const lastUrlSegment = this.entityObject['holdsPosition.person-permid'][0]["Peron's PermID"][0].split('?')[0].split('/').pop()
      queryParam = lastUrlSegment;
    }
    else {
      queryParam = this.entityObject['PERM ID'][0]
    }

    if (this.userIsAuthenticated) {
      let tokenValue = this.auth.getAuthData();
      console.log("this.auth.getAuthToken:::::",this.auth.getAuthToken())
      params = {
        format: formatStr,
        query: queryParam,
        userLoggedIn: this.userIsAuthenticated,
        userToken: this.auth.getAuthToken()
      }
      console.log("inside::", this.userIsAuthenticated,+"token::", params.userToken);
    } else {
      paramQuery = {
        format: formatStr,
        userLoggedIn: this.userIsAuthenticated,
        query: queryParam,
        userToken: environment.accesstokenQA
      }
      console.log("else:: unregister::",this.userIsAuthenticated+"Token::"+paramQuery.userToken)
    }    
    window.sessionStorage.setItem("RDFData", JSON.stringify({
      headerName: headerText,
      params: this.userIsAuthenticated ? params : paramQuery
    }));
    window.open("/viewData", "_blank");
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  showFileFormatOptions(displayModel) {
    this.dialog.open(displayModel)
  }

  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }

}