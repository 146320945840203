import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../shared/popup/popup.component';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
import { MatchService } from '../shared/services/recordMatch/match.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-record-matching',
  templateUrl: './record-matching.component.html',
  styleUrls: ['./record-matching.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecordMatchingComponent implements OnInit {
  dataType: string = '';
  fileTypeInput: string;
  uploadFile: boolean = false;
  fileInput: File = null;
  isSubmitBtnDisabled: boolean= false;
  fileToUpload: string = null;
  fileTypes = ['Organization', 'Instrument', 'Quote', 'Person'];
  numberOfMatches = [1,2,3,4,5];
  defaultNumberofMatch = 1;
  matchResults: any;
  errorResponse: string;
  showErrorMessage:boolean;
  isLoading: boolean = false;


  constructor(private matchService: MatchService, private dialog: MatDialog, private title: Title) { }

  ngOnInit() {
    this.title.setTitle('Record Matching' + ' | PermID');
  }

  handleFileInput(files: FileList) {
    if(files.length < 1){
      this.isSubmitBtnDisabled = true;
    } else {
      this.isSubmitBtnDisabled = false;
    }

    this.fileInput = files.item(0);
    this.fileToUpload = this.fileInput.name;
  }

  handleFileType(dataType) {
    this.fileTypeInput = dataType;
    this.uploadFile = false;
    this.fileToUpload = null;
    this.showErrorMessage = false;
  }
  onRecordMatch(matchparams) {
    this.isLoading = true;
    this.uploadFile = false;
    this.matchService.getMatchRecords(this.fileInput, matchparams.fileType, matchparams.matchRecord).subscribe(responseData =>{
      this.isLoading = false;
      this.uploadFile = true;
      if(responseData && responseData['errorCode'] === 0){
        this.matchResults = responseData;
      }else {
        alert(responseData['errorCodeMessage']);
      }
    },
    errorRes => {
      this.isLoading = false;
      this.errorResponse = errorRes["error"].errorCodeMessage;
      alert(this.errorResponse);
    })
  }
  downloadTemplate(datatype) {
    if(datatype) {
      this.showErrorMessage = false;
      let link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = 'assets/files/' + datatype + '_input_template_with_examples.csv';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      this.showErrorMessage = true
    }
  }
  scrollToInstruction(datatype) {
    if(datatype) {
      this.showErrorMessage = false;
      document.getElementById("match_instruction").scrollIntoView({
        behavior: 'smooth'
      });
    } else {
      this.showErrorMessage = true;
    }
  }
  downloadFile() {
    // console.log('REached')
    let fileName = this.fileToUpload;
    // console.log(fileName);
    let fileNameWithoutExtension = fileName.split('.')[0];
    let csv = this.matchResults.outputContentResponse;
    let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
    // window.navigator.msSaveBlob(blob, "_matched_records.csv");
    if(navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileNameWithoutExtension + "_matched_records.csv");
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileNameWithoutExtension + "_matched_records.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }

}
