import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  tokenKey: any;
  finalKey: any;
  constructor(private auth: AuthService){}

  intercept(req: HttpRequest<any>, next:HttpHandler) {
    const authToken = this.auth.getAuthToken();
    this.tokenKey = this.auth.getAuthData();
    if(this.tokenKey == undefined || this.tokenKey == null){
      this.finalKey = authToken;
    } else{
      this.finalKey = this.tokenKey.apiGardenToken;
    }
    console.log("Tokenkey>>",this.tokenKey);
    if(this.finalKey) {
      const authRequest = req.clone({
        headers: req.headers.set("X-AG-Access-Token", this.finalKey)
      })
      return next.handle(authRequest);
    } else {
      return next.handle(req);
    }
  }
}
