import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class MatchService {

  api = environment.apiUrl;

  constructor(private http: HttpClient, private auth: AuthService) { }


  getMatchRecords(fileInput: File, fileType, numOfMatchRecord) {
    console.log(fileInput);
    console.log(fileType);
    console.log(numOfMatchRecord);
    let tokenKey = this.auth.getAuthData();
    let header = new HttpHeaders();
    //let param = new HttpParams();
    if(tokenKey != undefined  || tokenKey !=null){
      header = header.append('X-Ag-Access-Token',''+tokenKey.apiGardenToken);
    }
    header = header.append('x-openmatch-numberOfMatchesPerRecord',''+numOfMatchRecord);
    header = header.append('x-openmatch-datatype', ''+fileType);

    const formData: FormData = new FormData();
    formData.append('file', fileInput );
    return this.http.post(this.api + '/match/filetocsv', formData, {
      headers: header
    })
    .pipe(
      timeout(300000),
      map(response => {
      return response;
    }),
    catchError(errRes => {
      return throwError(errRes);
    })
    )
  }
}
