import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  api = environment.apiUrl;

  userIsAuthenticated = false;
  profile:any;
  apiToken:any;
  loginDataEmail: any;
  truidData: any;
  private authListnerSub: Subscription;

  constructor(private router: Router, private auth: AuthService,public dialog: MatDialog) { }

  ngOnInit() {
    this.userIsAuthenticated = this.auth.getAuthStatus();
    this.authListnerSub = this.auth.getAuthListener().subscribe(isAuthenticated=>{
      this.userIsAuthenticated = isAuthenticated;
      if(this.userIsAuthenticated) {
        this.profile = this.auth.getUserData();
        if(this.profile == undefined){
          this.profile = this.auth.getAuthData();
        }
        this.apiToken = this.auth.getAuthToken();
      }

    })

    if(this.userIsAuthenticated) {
      this.profile = this.auth.getAuthData();
      this.apiToken = this.auth.getAuthToken();
      console.log("this.auth.getAuthToken..header page:::",this.auth.getAuthToken());
    }

  }

  openPopup(dispalyToken) {
    console.log("api token::", dispalyToken)
    let dialogRef = this.dialog.open(dispalyToken, {
      width: '600px',
      // data: { name: this.name, animal: this.animal }
  });

  dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
  });
  }

  logout() {
    this.auth.logout();
  }

  ngOnDestroy() {
    this.authListnerSub.unsubscribe();
  }
}
