import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.scss']
})
export class ValidateUserComponent implements OnInit {

  constructor(public route: ActivatedRoute, private auth: AuthService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((param:Params) => {
      this.auth.getUserDetails(param);
      console.log("authData::",this.auth.getAuthData());  
    })
  }

}
