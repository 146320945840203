import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { PopupComponent } from '../shared/popup/popup.component';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  userIsAuthenticated = false;
  profile:any;
  private authListnerSub: Subscription;

  constructor(public dialog: MatDialog, private router: Router,private auth: AuthService, private title: Title) { }

  ngOnInit() {
    this.title.setTitle('PermID');
    this.userIsAuthenticated = this.auth.getAuthStatus();
    this.authListnerSub = this.auth.getAuthListener().subscribe(isAuthenticated=>{
      this.userIsAuthenticated = isAuthenticated;
      if(this.userIsAuthenticated) {
        this.profile = this.auth.getUserData();
        // console.log(this.profile);
      }

    })
  }

  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }

  iconPopup(displayIconInfo) {
    this.dialog.open(displayIconInfo,{
      width: '600px'
    })
  }
}
