import { AfterContentChecked, AfterViewInit, ContentChild, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, OnChanges , SimpleChange , Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DropdownDirective } from '../dropdown.directive';
import { PopoverComponent } from '../popover/popover.component';
import { rightClickDetected , leftClickDetected, setrightClickDetected , setleftClickDetected} from "../../globals";

@Component({
  selector: 'app-entity-popup',
  templateUrl: './entity-popup.component.html',
  styleUrls: ['./entity-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class EntityPopupComponent implements OnInit, AfterViewInit ,OnChanges {

  @Input() document:any;
  @Input() entityData: any;
  @Input() relationData: any;
  currentvalue: any;
  @ViewChild('popover', {static: false}) popover : NgbPopover;
  @ViewChild('popupDiv', {static: false}) popupContainer : ElementRef;
  // @HostBinding('class.open') isOpen = false;
  // @ViewChild('popover') public popover: NgbPopover;
  // @ViewChild('popupDiv') popupContainer: ElementRef;

  popupInfo: any;
  popupIdentity:any=0;
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  data5: any;
  data6: any;
  data7: any;
  data8: any;
  private popup: ComponentRef<PopoverComponent>;
 
  constructor(private render: Renderer2, private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
      private resolver: ComponentFactoryResolver) {  }

  ngOnInit() 
  {
    //console.log("getClassName :",this.getClassName(element,false));
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) 
  {
    // console.log('ngOnChanges called after innerHTML change');
    for (var i = 0; i < this.entityData.length; i++) 
      {
        for (var j = 0; j < this.entityData[i].instances.length; j++)
        {
          this.entityData[i].instances[j]['instance_free'] = true;
        }
      }
    this.ngAfterViewInit();
  }

  ngAfterViewInit() 
  {
  setTimeout(() => 
  {
      let dom: HTMLElement = this.el.nativeElement;
      
      let elements1 = dom.querySelectorAll(".highlight-event-entity");
      let elements2 = dom.querySelectorAll(".highlight-event-company");
      let elements3 = dom.querySelectorAll(".highlight-entity");
      let elements4 = dom.querySelectorAll(".highlight-company");
      let elements5 = dom.querySelectorAll(".highlight-event");
      // console.log("highlight-event-company elements :", elements2);
      // console.log("highlight-event-entity elements :", elements1);
      // console.log("highlight-event elements :", elements5);
      // console.log("highlight-company elements :", elements4);
      // console.log("highlight-entity elements :", elements3);

//---------------------------------------------------------------------------------------------------------     
      elements5.forEach(element =>{
        
        let factory = this.resolver.resolveComponentFactory(PopoverComponent);
        this.popup = this.viewContainerRef.createComponent(factory);
        this.render.appendChild(element, this.popup.location.nativeElement);
        let classNameStr = this.getClassName(element,false);
        this.popup.instance.classNameStr=classNameStr;
        element['elementTiming']=classNameStr;
        //console.log("getClassName :",this.getClassName(element,false));
        this.render.addClass(this.popup.location.nativeElement, classNameStr);
        this.render.listen(element, 'click', (event)=>{
          let e = event.target.querySelector('.'+classNameStr);
          if(e)
              { 
                e.classList.remove('open');
                e.classList.remove('element_css');
                e.classList.remove('expand_css');
                e.classList.remove('elementcss');
                e.classList.remove('expandcss');
                e.classList.remove('top_nav_css');
              }
        });
        this.render.listen(element, 'mouseenter', (event)=>
          {
              event.stopPropagation();
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    // console.log("5:::event",event.screenX+"event.screeny::"+event.layerY+"e::"+ e.classList[0]);
                    if(event.layerY > 270){
                      e.classList.add('top_nav_css');
                    }
                    if(event.screenX >=1000){
                      e.classList.add('expand_css');
                    } else if(event.screenX > 650 && event.screenX < 730) {
                      e.classList.add('elementcss');
                    } else if(event.screenX > 730 && event.screenX < 900){
                      e.classList.add('element_css');
                    } else if(event.screenX > 900 && event.screenX < 1000){
                      e.classList.add('expandcss');
                    } else {
                     e.classList.add('open');
                    }
                  }             
          })
        this.render.listen(element, 'mouseleave', (event)=>
          {
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    e.classList.remove('element_css');
                    e.classList.remove('expand_css');
                    e.classList.remove('elementcss');
                    e.classList.remove('expandcss');
                    e.classList.remove('open');
                    e.classList.remove('top_nav_css');
                  }
          })

        this.relationData.forEach((instance, index) => 
          {
              if(!this.popup.instance.AssignmentDone)
                {
                  if( index == Number(element['id']) )
                    {
                      this.popupIdentity=this.popupIdentity+1;
                      this.popup.instance.popupInstanceId= this.popupIdentity;
                      this.popup.instance.AssignmentDone=true;
                      this.popup.instance.relCategory = this.relationData[index];
                    }
                }
                if(!this.popup.instance.rO_relAssignmentDone)
                {
                  if( index == Number(element['accessKey']) && element['accessKey']!="")
                    {
                      this.popup.instance.rorelCategory = this.relationData[index];
                      this.popup.instance.rO_relAssignmentDone=true;
                    }
                } // rO_relAssignmentDone
              if(!this.popup.instance.sRO_relAssignmentDone)
                {
                  if( index == Number(element['nonce']) && element['nonce']!="")
                    {
                      this.popup.instance.srorelCategory = this.relationData[index];
                      this.popup.instance.sRO_relAssignmentDone=true;
                    }
                } // sRO_relAssignmentDone
          }); // this.relationData.forEach       
        }) //elements5 Processing end here 
  //--------------------------------------------------------------------------------------------------------------
      elements4.forEach(element =>{
        let instanceNo =0;
        let entityNo =0;
        instanceNo = Number(element['accessKey']);
        entityNo = Number(element['id']);
        let factory = this.resolver.resolveComponentFactory(PopoverComponent);
        this.popup = this.viewContainerRef.createComponent(factory);
        this.render.appendChild(element, this.popup.location.nativeElement);
        let classNameStr = this.getClassName(element,false);
        this.popup.instance.classNameStr=classNameStr;
        element['elementTiming']=classNameStr;
        //console.log("getClassName :",this.getClassName(element,false));
        this.render.addClass(this.popup.location.nativeElement, classNameStr);
        this.render.listen(element, 'click', (event)=>{
          let e = event.target.querySelector('.'+classNameStr);
          if(e)
              { 
                e.classList.remove('open');
                e.classList.remove('element_css');
                e.classList.remove('expand_css');
                e.classList.remove('elementcss');
                e.classList.remove('expandcss');
                e.classList.remove('top_nav_css');
              }
        });
        this.render.listen(element, 'mouseenter', (event)=>
          {
              event.stopPropagation();
              classNameStr=String(element['elementTiming']);
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  {
                 //  console.log("4:::event",event.screenX+"event.screeny::"+event.layerY+"e::"+ e.classList[0]);
                   if(event.layerY > 270){
                    e.classList.add('top_nav_css');
                  }
                   if(event.screenX >=1000){
                    e.classList.add('expand_css');
                  } else if(event.screenX > 650 && event.screenX < 730) {
                    e.classList.add('elementcss');
                  } else if(event.screenX > 730 && event.screenX < 900){
                    e.classList.add('element_css');
                  } else if(event.screenX > 900 && event.screenX < 1000){
                    e.classList.add('expandcss');
                  } else {
                      e.classList.add('open');
                    }
                  }
          })
        this.render.listen(element, 'mouseleave', (event)=>
          {
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    e.classList.remove('element_css');
                    e.classList.remove('expand_css');
                    e.classList.remove('elementcss');
                    e.classList.remove('expandcss');
                    e.classList.remove('open');
                    e.classList.remove('top_nav_css');
                  }
          })
        this.render.listen(element, 'click', (event)=>
          {  
          
          let e = event.target.querySelector('.'+classNameStr);
            if(e)
              {
                //console.log("e start::", e.classList)
                  e.classList.remove('open');
                  e.classList.remove('element_css');
                  e.classList.remove('expand_css');
                  e.classList.remove('elementcss');
                  e.classList.remove('expandcss');
                  e.classList.remove('top_nav_css');
              }
            if(rightClickDetected)
            {
              elements4.forEach(elementInner =>
              {
                  if(Number(elementInner['id'])==entityNo && Number(elementInner['accessKey'])==instanceNo+1)
                      {
                        //console.log("Opening elemnts4 Right",elementInner['elementTiming']);
                        this.data1= elementInner.querySelector('.'+String(elementInner['elementTiming']));
                        if(this.data2 !=undefined){
                          this.data2.classList.remove('open');
                          this.data2.classList.remove('element_css');
                          this.data2.classList.remove('expand_css');
                          this.data2.classList.remove('elementcss');
                          this.data2.classList.remove('expandcss');
                          this.data2.classList.remove('top_nav_css');
                        }
                        if(this.data3 !=undefined){
                          this.data3.classList.remove('open');
                          this.data3.classList.remove('element_css');
                          this.data3.classList.remove('expand_css');
                          this.data3.classList.remove('elementcss');
                          this.data3.classList.remove('expandcss');
                          this.data3.classList.remove('top_nav_css');
                        }
                        if(this.data4 !=undefined){
                          this.data4.classList.remove('open');
                          this.data4.classList.remove('element_css');
                          this.data4.classList.remove('expand_css');
                          this.data4.classList.remove('elementcss');
                          this.data4.classList.remove('expandcss');
                          this.data4.classList.remove('top_nav_css');
                        }
                        if(this.data5 !=undefined){
                          this.data5.classList.remove('open');
                          this.data5.classList.remove('element_css');
                          this.data5.classList.remove('expand_css');
                          this.data5.classList.remove('elementcss');
                          this.data5.classList.remove('expandcss');
                          this.data5.classList.remove('top_nav_css');
                        }
                        if(this.data6 !=undefined){
                          this.data6.classList.remove('open');
                          this.data6.classList.remove('element_css');
                          this.data6.classList.remove('expand_css');
                          this.data6.classList.remove('elementcss');
                          this.data6.classList.remove('expandcss');
                          this.data6.classList.remove('top_nav_css');
                        }
                        if(this.data7 !=undefined){
                          this.data7.classList.remove('open');
                          this.data7.classList.remove('element_css');
                          this.data7.classList.remove('expand_css');
                          this.data7.classList.remove('elementcss');
                          this.data7.classList.remove('expandcss');
                          this.data7.classList.remove('top_nav_css');
                        }
                        if(this.data8 !=undefined){
                          this.data8.classList.remove('open');
                          this.data8.classList.remove('element_css');
                          this.data8.classList.remove('expand_css');
                          this.data8.classList.remove('elementcss');
                          this.data8.classList.remove('expandcss');
                          this.data8.classList.remove('top_nav_css');
                        }
                        console.log("this.data2", this.data2+"Data3::"+this.data3+"Data4::"+this.data4+"Data5::"+this.data5+"Data6::"+this.data6)
                        this.data1.classList.add('open');
                        this.data1.classList.add('element_css');
                        this.data1.classList.add('expand_css');
                        this.data1.classList.add('elementcss');
                        this.data1.classList.add('expandcss');
                        this.data1.classList.add('top_nav_css');
                      }
              });
              elements2.forEach(elementInner1 =>
              {
                    if(Number(elementInner1['id'])==entityNo && Number(elementInner1['accessKey'])==instanceNo+1)
                        {
                          //console.log("Opening elemnts2 Right",elementInner1['elementTiming']);
                          this.data2= elementInner1.querySelector('.'+String(elementInner1['elementTiming']));
                          if(this.data1 !=undefined){
                            this.data1.classList.remove('open');
                            this.data1.classList.remove('element_css');
                            this.data1.classList.remove('expand_css');
                            this.data1.classList.remove('elementcss');
                            this.data1.classList.remove('expandcss');
                            this.data1.classList.remove('top_nav_css');
                          }
                          if(this.data3 !=undefined){
                            this.data3.classList.remove('open');
                            this.data3.classList.remove('element_css');
                            this.data3.classList.remove('expand_css');
                            this.data3.classList.remove('elementcss');
                            this.data3.classList.remove('expandcss');
                            this.data3.classList.remove('top_nav_css');
                          }
                          if(this.data4 !=undefined){
                            this.data4.classList.remove('open');
                            this.data4.classList.remove('element_css');
                            this.data4.classList.remove('expand_css');
                            this.data4.classList.remove('elementcss');
                            this.data4.classList.remove('expandcss');
                            this.data4.classList.remove('top_nav_css');
                          }
                          if(this.data5 !=undefined){
                            this.data5.classList.remove('open');
                            this.data5.classList.remove('element_css');
                            this.data5.classList.remove('expand_css');
                            this.data5.classList.remove('elementcss');
                            this.data5.classList.remove('expandcss');
                            this.data5.classList.remove('top_nav_css');
                          }
                          if(this.data6 !=undefined){
                            this.data6.classList.remove('open');
                            this.data6.classList.remove('element_css');
                            this.data6.classList.remove('expand_css');
                            this.data6.classList.remove('elementcss');
                            this.data6.classList.remove('expandcss');
                            this.data6.classList.remove('top_nav_css');
                          }
                          if(this.data7 !=undefined){
                            this.data7.classList.remove('open');
                            this.data7.classList.remove('element_css');
                            this.data7.classList.remove('expand_css');
                            this.data7.classList.remove('elementcss');
                            this.data7.classList.remove('expandcss');
                            this.data7.classList.remove('top_nav_css');
                          }
                          if(this.data8 !=undefined){
                            this.data8.classList.remove('open');
                            this.data8.classList.remove('element_css');
                            this.data8.classList.remove('expand_css');
                            this.data8.classList.remove('elementcss');
                            this.data8.classList.remove('expandcss');
                            this.data8.classList.remove('top_nav_css');
                          }
                          this.data2.classList.add('open');
                          this.data2.classList.add('element_css');
                          this.data2.classList.add('expand_css');
                          this.data2.classList.add('elementcss');
                          this.data2.classList.add('expandcss');
                          this.data2.classList.add('top_nav_css');
                        }
              });
            } // rightClickDetect
            if(leftClickDetected)
            {
              elements4.forEach(elementInner =>
              {
                  if(Number(elementInner['id'])==entityNo && Number(elementInner['accessKey'])==instanceNo-1)
                      {
                        //console.log("Opening elemnts4 left",elementInner['elementTiming']);
                        this.data3= elementInner.querySelector('.'+String(elementInner['elementTiming']));
                        if(this.data2 !=undefined){
                          this.data2.classList.remove('open');
                          this.data2.classList.remove('element_css');
                          this.data2.classList.remove('expand_css');
                          this.data2.classList.remove('elementcss');
                          this.data2.classList.remove('expandcss');
                          this.data2.classList.remove('top_nav_css');
                        }
                        if(this.data1 !=undefined){
                          this.data1.classList.remove('open');
                          this.data1.classList.remove('element_css');
                          this.data1.classList.remove('expand_css');
                          this.data1.classList.remove('elementcss');
                          this.data1.classList.remove('expandcss');
                          this.data1.classList.remove('top_nav_css');
                        }
                        if(this.data4 !=undefined){
                          this.data4.classList.remove('open');
                          this.data4.classList.remove('element_css');
                          this.data4.classList.remove('expand_css');
                          this.data4.classList.remove('elementcss');
                          this.data4.classList.remove('expandcss');
                          this.data4.classList.remove('top_nav_css');
                        }
                        if(this.data5 !=undefined){
                          this.data5.classList.remove('open');
                          this.data5.classList.remove('element_css');
                          this.data5.classList.remove('expand_css');
                          this.data5.classList.remove('elementcss');
                          this.data5.classList.remove('expandcss');
                          this.data5.classList.remove('top_nav_css');
                        }
                        if(this.data6 !=undefined){
                          this.data6.classList.remove('open');
                          this.data6.classList.remove('element_css');
                          this.data6.classList.remove('expand_css');
                          this.data6.classList.remove('elementcss');
                          this.data6.classList.remove('expandcss');
                          this.data6.classList.remove('top_nav_css');
                        }
                        if(this.data7 !=undefined){
                          this.data7.classList.remove('open');
                          this.data7.classList.remove('element_css');
                          this.data7.classList.remove('expand_css');
                          this.data7.classList.remove('elementcss');
                          this.data7.classList.remove('expandcss');
                          this.data7.classList.remove('top_nav_css');
                        }
                        if(this.data8 !=undefined){
                          this.data8.classList.remove('open');
                          this.data8.classList.remove('element_css');
                          this.data8.classList.remove('expand_css');
                          this.data8.classList.remove('elementcss');
                          this.data8.classList.remove('expandcss');
                          this.data8.classList.remove('top_nav_css');
                        }
                        this.data3.classList.add('open');
                        this.data3.classList.add('element_css');
                        this.data3.classList.add('expand_css');
                        this.data3.classList.add('elementcss');
                        this.data3.classList.add('expandcss');
                        this.data3.classList.add('top_nav_css');
                      }
              });
              elements2.forEach(elementInner1 =>
              {
                    if(Number(elementInner1['id'])==entityNo && Number(elementInner1['accessKey'])==instanceNo-1)
                        {
                          //console.log("Opening elemnts2 Left",elementInner1['elementTiming']);
                          this.data4= elementInner1.querySelector('.'+String(elementInner1['elementTiming']));
                          if(this.data2 !=undefined){
                            this.data2.classList.remove('open');
                            this.data2.classList.remove('element_css');
                            this.data2.classList.remove('expand_css');
                            this.data2.classList.remove('elementcss');
                            this.data2.classList.remove('expandcss');
                            this.data2.classList.remove('top_nav_css');
                          }
                          if(this.data3 !=undefined){
                            this.data3.classList.remove('open');
                            this.data3.classList.remove('element_css');
                            this.data3.classList.remove('expand_css');
                            this.data3.classList.remove('elementcss');
                            this.data3.classList.remove('expandcss');
                            this.data3.classList.remove('top_nav_css');
                          }
                          if(this.data1 !=undefined){
                            this.data1.classList.remove('open');
                            this.data1.classList.remove('element_css');
                            this.data1.classList.remove('expand_css');
                            this.data1.classList.remove('elementcss');
                            this.data1.classList.remove('expandcss');
                            this.data1.classList.remove('top_nav_css');
                          }
                          if(this.data5 !=undefined){
                            this.data5.classList.remove('open');
                            this.data5.classList.remove('element_css');
                            this.data5.classList.remove('expand_css');
                            this.data5.classList.remove('elementcss');
                            this.data5.classList.remove('expandcss');
                            this.data5.classList.remove('top_nav_css');
                          }
                          if(this.data6 !=undefined){
                            this.data6.classList.remove('open');
                            this.data6.classList.remove('element_css');
                            this.data6.classList.remove('expand_css');
                            this.data6.classList.remove('elementcss');
                            this.data6.classList.remove('expandcss');
                            this.data6.classList.remove('top_nav_css');
                          }
                          if(this.data7 !=undefined){
                            this.data7.classList.remove('open');
                            this.data7.classList.remove('element_css');
                            this.data7.classList.remove('expand_css');
                            this.data7.classList.remove('elementcss');
                            this.data7.classList.remove('expandcss');
                            this.data7.classList.remove('top_nav_css');
                          }
                          if(this.data8 !=undefined){
                            this.data8.classList.remove('open');
                            this.data8.classList.remove('element_css');
                            this.data8.classList.remove('expand_css');
                            this.data8.classList.remove('elementcss');
                            this.data8.classList.remove('expandcss');
                            this.data8.classList.remove('top_nav_css');
                          }
                          // this.getClassName(elementInner1, true)
                          this.data4.classList.add('open');
                          this.data4.classList.add('element_css');
                          this.data4.classList.add('expand_css');
                          this.data4.classList.add('elementcss');
                          this.data4.classList.add('expandcss');
                          this.data4.classList.add('top_nav_css');
                        }
              });
            } // leftClickDetect
          })
        
          this.entityData.forEach((instance, index) => {           
          for (let i = 0; i < this.entityData[index].instances.length; i++) 
            {
                if(!this.popup.instance.AssignmentDone)
                {                
                    if(index == Number(element['id']) && i == Number(element['accessKey'])  )
                    {
                      this.popupIdentity=this.popupIdentity+1;
                      this.popup.instance.popupInstanceId = i;
                      this.popup.instance.category = this.entityData[index];
                      this.popup.instance.AssignmentDone=true;
                      //console.log("Bingo Match Found");
                    }                                  
                } //AssignmentDone
            } // for accessing each instance in entity data element         
          });       
       }) //elements4 Processing ends
//--------------------------------------------------------------------------------------------------------------
      elements3.forEach(element =>{
        let factory = this.resolver.resolveComponentFactory(PopoverComponent);
        this.popup = this.viewContainerRef.createComponent(factory);
        this.render.appendChild(element, this.popup.location.nativeElement);
        let classNameStr = this.getClassName(element,false);
        this.popup.instance.classNameStr=classNameStr;
        element['elementTiming']=classNameStr;
        //console.log("getClassName :",this.getClassName(element,false));
        this.render.addClass(this.popup.location.nativeElement, classNameStr);
        this.render.listen(element, 'click', (event)=>{
          let e = event.target.querySelector('.'+classNameStr);
          if(e)
              { 
                e.classList.remove('open');
                e.classList.remove('element_css');
                e.classList.remove('expand_css');
                e.classList.remove('elementcss');
                e.classList.remove('expandcss');
                e.classList.remove('top_nav_css');
              }
        });
        this.render.listen(element, 'mouseenter', (event)=>
          {
              event.stopPropagation();
              classNameStr=String(element['elementTiming']);
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  {
                 //  console.log("3:::event",event.screenX+"event.screeny::"+event.layerY+"e::"+ e.classList[0]);
                  if(event.layerY >270){
                    e.classList.add('top_nav_css');
                  }
                  if(event.screenX >=1000){
                    e.classList.add('expand_css');
                  } else if(event.screenX > 650 && event.screenX < 730) {
                    e.classList.add('elementcss');
                  } else if(event.screenX > 730 && event.screenX < 900){
                    e.classList.add('element_css');
                  } else if(event.screenX > 900 && event.screenX < 1000){
                    e.classList.add('expandcss');
                  } else {
                      e.classList.add('open');
                    }
                  }
          })
        this.render.listen(element, 'mouseleave', (event)=>
          {
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    e.classList.remove('element_css');
                    e.classList.remove('expand_css');
                    e.classList.remove('elementcss');
                    e.classList.remove('expandcss');
                    e.classList.remove('open');
                    e.classList.remove('top_nav_css');
                  }
          })

            this.entityData.forEach((instance, index) => {
            for (let i = 0; i < this.entityData[index].instances.length; i++) 
            {
                if(!this.popup.instance.AssignmentDone)
                {
                  if(index == Number(element['id']) && i == Number(element['accessKey'])  )
                    {
                      this.popupIdentity=this.popupIdentity+1;
                      this.popup.instance.category = this.entityData[index];
                      this.popup.instance.popupInstanceId = i;
                      this.popup.instance.AssignmentDone=true;
                    }
                } //AssignmentDone
            } // for accessing each instance in entity data element          
          });       
       }) //elements3 Processing ends
//--------------------------------------------------------------------------------------------------------------       
      elements1.forEach(element =>{
        let factory = this.resolver.resolveComponentFactory(PopoverComponent);
        this.popup = this.viewContainerRef.createComponent(factory);
        this.render.appendChild(element, this.popup.location.nativeElement);
        let classNameStr = this.getClassName(element,true);
        this.popup.instance.classNameStr=classNameStr;
        element['elementTiming']=classNameStr;
        //console.log("getClassName :",this.getClassName(element,true));
        this.render.addClass(this.popup.location.nativeElement, classNameStr);
        this.render.listen(element, 'click', (event)=>{
          let e = event.target.querySelector('.'+classNameStr);
          if(e)
              { 
                e.classList.remove('open');
                e.classList.remove('element_css');
                e.classList.remove('exapnd_css');
                e.classList.remove('elementcss');
                e.classList.remove('expandcss');
                e.classList.remove('top_nav_css');
              }
        });
        this.render.listen(element, 'mouseenter', (event)=>
          {
              event.stopPropagation();
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  {
                 //  console.log("1:::event",event.screenX+"event.screeny::"+event.layerY+"e::"+ e.classList[0]);
                  if(event.layerY >270){
                    e.classList.add('top_nav_css');
                  }
                   if(event.screenX >=1000){
                    e.classList.add('expand_css');
                  } else if(event.screenX > 650 && event.screenX < 730) {
                    e.classList.add('elementcss');
                  } else if(event.screenX > 730 && event.screenX < 900){
                    e.classList.add('element_css');
                  } else if(event.screenX > 900 && event.screenX < 1000){
                    e.classList.add('expandcss');
                  } else {
                      e.classList.add('open');
                    }
                  }
          })
        this.render.listen(element, 'mouseleave', (event)=>
          {
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    e.classList.remove('element_css');
                    e.classList.remove('expand_css');
                    e.classList.remove('elementcss');
                    e.classList.remove('expandcss');
                    e.classList.remove('open');
                    e.classList.remove('top_nav_css');
                  }
          })                
            
          this.entityData.forEach((instance, index) => {  
          for (let i = 0; i < this.entityData[index].instances.length; i++) 
              {
                if(!this.popup.instance.AssignmentDone)
                  {
                    if(index == Number(element['id']) && i == Number(element['accessKey'])  )
                    {
                        this.popupIdentity=this.popupIdentity+1;
                        this.popup.instance.category = this.entityData[index];
                        this.popup.instance.popupInstanceId = i;
                        this.popup.instance.AssignmentDone=true;
                    }
                  } // AssignmentDone
              } // for accessing each instance in entity data element 
            }); // this.entityData.forEach
            
          this.relationData.forEach((instance, index) => 
            {
                if(!this.popup.instance.relAssignmentDone)
                {
                  if( index == Number(element['nonce']) )
                    {
                      this.popup.instance.relCategory = this.relationData[index];
                      this.popup.instance.relAssignmentDone=true;
                      //console.log("Relation Category assigned:",this.popup.instance.relCategory);
                      //console.log("nonce :",element['nonce']);
                    }
                }
                if(!this.popup.instance.rO_relAssignmentDone)
                {
                if( index == Number(element['lang']) && element['lang']!="")
                  {
                    this.popup.instance.rorelCategory = this.relationData[index];
                    this.popup.instance.rO_relAssignmentDone=true;
                  }
                } // rO_relAssignmentDone
                if(!this.popup.instance.sRO_relAssignmentDone)
                {
                if( index == Number(element['elementTiming']) && element['elementTiming']!="")
                  {
                    this.popup.instance.srorelCategory = this.relationData[index];
                    this.popup.instance.sRO_relAssignmentDone=true;
                  }
                } // sRO_relAssignmentDone
            }) // forEach loop acessing all relations
       }) //elements1 Processing ends 
//--------------------------------------------------------------------------------------------------------------              
      elements2.forEach(element =>{
        let instanceNo =0;
        let entityNo =0;
        instanceNo = Number(element['accessKey']);
        entityNo = Number(element['id']);
        let factory = this.resolver.resolveComponentFactory(PopoverComponent);
        this.popup = this.viewContainerRef.createComponent(factory);
        this.render.appendChild(element, this.popup.location.nativeElement);
        let classNameStr = this.getClassName(element,true);
        this.popup.instance.classNameStr=classNameStr;
        element['elementTiming']=classNameStr;
        this.render.addClass(this.popup.location.nativeElement,classNameStr);
        this.render.listen(element, 'click', (event)=>{
          let e = event.target.querySelector('.'+classNameStr);
          if(e)
              { 
                e.classList.remove('open');
                e.classList.remove('element_css');
                e.classList.remove('expand_css');
                e.classList.remove('elementcss');
                e.classList.remove('expandcss');
                e.classList.remove('top_nav_css');
              }
        });
        this.render.listen(element, 'mouseenter', (event)=>
          {
              this.popup.instance.hidepopup = true;
              event.stopPropagation();
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  {
                    //console.log("2:::event",event.screenX+"event.screeny::"+event.layerY+"e::"+ e.classList[0]);
                    if(event.layerY> 270){
                      e.classList.add('top_nav_css');
                    }
                    if(event.screenX >=1000){
                      e.classList.add('expand_css');
                    } else if(event.screenX > 650 && event.screenX < 730) {
                      e.classList.add('elementcss');
                    } else if(event.screenX > 730 && event.screenX < 900){
                      e.classList.add('element_css');
                    } else if(event.screenX > 900 && event.screenX < 1000){
                      e.classList.add('expandcss');
                    } else {
                      e.classList.add('open');
                    }
                  }
          })
        this.render.listen(element, 'mouseleave', (event)=>
          {
              let e = event.target.querySelector('.'+classNameStr);
              if(e)
                  { 
                    e.classList.remove('element_css');
                    e.classList.remove('expand_css');
                    e.classList.remove('elementcss');
                    e.classList.remove('expandcss');
                    e.classList.remove('open');
                    e.classList.remove('top_nav_css');
                  }
          }) 
          
          if(this.popup.instance.clickdirection){
            //console.log("check::data...")
          }  
        this.render.listen(element, 'click', (event)=>
          {  
            let e = event.target.querySelector('.'+classNameStr);
            if(e)
              { 
                  e.classList.remove('open');
                  e.classList.remove('element_css');
                  e.classList.remove('expand_css');
                  e.classList.remove('elementcss');
                  e.classList.remove('expandcss');
                  e.classList.remove('top_nav_css');
              }
            if(rightClickDetected)
            {
              elements4.forEach(elementInner =>
              {
                  if(Number(elementInner['id'])==entityNo && Number(elementInner['accessKey'])==instanceNo+1)
                      {
                        //console.log("Opening Popup elements4 Right",elementInner['elementTiming']);
                        this.data5= elementInner.querySelector('.'+String(elementInner['elementTiming']));
                        if(this.data2 !=undefined){
                          this.data2.classList.remove('open');
                          this.data2.classList.remove('element_css');
                          this.data2.classList.remove('expand_css');
                          this.data2.classList.remove('elementcss');
                          this.data2.classList.remove('expandcss');
                          this.data2.classList.remove('top_nav_css');
                        }
                        if(this.data3 !=undefined){
                          this.data3.classList.remove('open');
                          this.data3.classList.remove('element_css');
                          this.data3.classList.remove('expand_css');
                          this.data3.classList.remove('elementcss');
                          this.data3.classList.remove('expandcss');
                          this.data3.classList.remove('top_nav_css');
                        }
                        if(this.data4 !=undefined){
                          this.data4.classList.remove('open');
                          this.data4.classList.remove('element_css');
                          this.data4.classList.remove('expand_css');
                          this.data4.classList.remove('elementcss');
                          this.data4.classList.remove('expandcss');
                          this.data4.classList.remove('top_nav_css');
                        }
                        if(this.data1 !=undefined){
                          this.data1.classList.remove('open');
                          this.data1.classList.remove('element_css');
                          this.data1.classList.remove('expand_css');
                          this.data1.classList.remove('elementcss');
                          this.data1.classList.remove('expandcss');
                          this.data1.classList.remove('top_nav_css');
                        }
                        if(this.data6 !=undefined){
                          this.data6.classList.remove('open');
                          this.data6.classList.remove('element_css');
                          this.data6.classList.remove('expand_css');
                          this.data6.classList.remove('elementcss');
                          this.data6.classList.remove('expandcss');
                          this.data6.classList.remove('top_nav_css');
                        }
                        if(this.data7 !=undefined){
                          this.data7.classList.remove('open');
                          this.data7.classList.remove('element_css');
                          this.data7.classList.remove('expand_css');
                          this.data7.classList.remove('elementcss');
                          this.data7.classList.remove('expandcss');
                          this.data7.classList.remove('top_nav_css');
                        }
                        if(this.data8 !=undefined){
                          this.data8.classList.remove('open');
                          this.data8.classList.remove('element_css');
                          this.data8.classList.remove('expand_css');
                          this.data8.classList.remove('elementcss');
                          this.data8.classList.remove('expandcss');
                          this.data8.classList.remove('top_nav_css');
                        }
                        this.data5.classList.add('open');
                        this.data5.classList.add('element_css');
                        this.data5.classList.add('expand_css');
                        this.data5.classList.add('elementcss');
                        this.data5.classList.add('expandcss');
                        this.data5.classList.add('top_nav_css');
                      }
              });
              elements2.forEach(elementInner1 =>
                {
                    if(Number(elementInner1['id'])==entityNo && Number(elementInner1['accessKey'])==instanceNo+1)
                        {
                          //console.log("Opening Popup elements2 Right",elementInner1['elementTiming']);
                          this.data6= elementInner1.querySelector('.'+String(elementInner1['elementTiming']));
                          if(this.data2 !=undefined){
                            this.data2.classList.remove('open');
                            this.data2.classList.remove('element_css');
                            this.data2.classList.remove('expand_css');
                            this.data2.classList.remove('elementcss');
                            this.data2.classList.remove('expandcss');
                            this.data2.classList.remove('top_nav_css');
                          }
                          if(this.data3 !=undefined){
                            this.data3.classList.remove('open');
                            this.data3.classList.remove('element_css');
                            this.data3.classList.remove('expand_css');
                            this.data3.classList.remove('elementcss');
                            this.data3.classList.remove('expandcss');
                            this.data3.classList.remove('top_nav_css');
                          }
                          if(this.data4 !=undefined){
                            this.data4.classList.remove('open');
                            this.data4.classList.remove('element_css');
                            this.data4.classList.remove('expand_css');
                            this.data4.classList.remove('elementcss');
                            this.data4.classList.remove('expandcss');
                            this.data4.classList.remove('top_nav_css');
                          }
                          if(this.data5 !=undefined){
                            this.data5.classList.remove('open');
                            this.data5.classList.remove('element_css');
                            this.data5.classList.remove('expand_css');
                            this.data5.classList.remove('elementcss');
                            this.data5.classList.remove('expandcss');
                            this.data5.classList.remove('top_nav_css');
                          }
                          if(this.data1 !=undefined){
                            this.data1.classList.remove('open');
                            this.data1.classList.remove('element_css');
                            this.data1.classList.remove('expand_css');
                            this.data1.classList.remove('elementcss');
                            this.data1.classList.remove('expandcss');
                            this.data1.classList.remove('top_nav_css');
                          }
                          if(this.data7 !=undefined){
                            this.data7.classList.remove('open');
                            this.data7.classList.remove('element_css');
                            this.data7.classList.remove('expand_css');
                            this.data7.classList.remove('elementcss');
                            this.data7.classList.remove('expandcss');
                            this.data7.classList.remove('top_nav_css');
                          }
                          if(this.data8 !=undefined){
                            this.data8.classList.remove('open');
                            this.data8.classList.remove('element_css');
                            this.data8.classList.remove('expand_css');
                            this.data8.classList.remove('elementcss');
                            this.data8.classList.remove('expandcss');
                            this.data8.classList.remove('top_nav_css');
                          }
                          this.data6.classList.add('open');
                          this.data6.classList.add('element_css');
                          this.data6.classList.add('expand_css');
                          this.data6.classList.add('elementcss');
                          this.data6.classList.add('expandcss');
                          this.data6.classList.add('top_nav_css');
                        }
                });
            } // rightClickDetect
            if(leftClickDetected)
            {
              elements4.forEach(elementInner =>
              {
                  if(Number(elementInner['id'])==entityNo && Number(elementInner['accessKey'])==instanceNo-1)
                      {
                        //console.log("Opening Popup elements4 Left",elementInner['elementTiming']);
                        this.data7= elementInner.querySelector('.'+String(elementInner['elementTiming']));
                        if(this.data2 !=undefined){
                          this.data2.classList.remove('open');
                          this.data2.classList.remove('element_css');
                          this.data2.classList.remove('expand_css');
                          this.data2.classList.remove('elementcss');
                          this.data2.classList.remove('expandcss');
                          this.data2.classList.remove('top_nav_css');
                        }
                        if(this.data3 !=undefined){
                          this.data3.classList.remove('open');
                          this.data3.classList.remove('element_css');
                          this.data3.classList.remove('expand_css');
                          this.data3.classList.remove('elementcss');
                          this.data3.classList.remove('expandcss');
                          this.data3.classList.remove('top_nav_css');
                        }
                        if(this.data4 !=undefined){
                          this.data4.classList.remove('open');
                          this.data4.classList.remove('element_css');
                          this.data4.classList.remove('expand_css');
                          this.data4.classList.remove('elementcss');
                          this.data4.classList.remove('expandcss');
                          this.data4.classList.remove('top_nav_css');
                        }
                        if(this.data5 !=undefined){
                          this.data5.classList.remove('open');
                          this.data5.classList.remove('element_css');
                          this.data5.classList.remove('expand_css');
                          this.data5.classList.remove('elementcss');
                          this.data5.classList.remove('expandcss');
                          this.data5.classList.remove('top_nav_css');
                        }
                        if(this.data6 !=undefined){
                          this.data6.classList.remove('open');
                          this.data6.classList.remove('element_css');
                          this.data6.classList.remove('expand_css');
                          this.data6.classList.remove('elementcss');
                          this.data6.classList.remove('expandcss');
                          this.data6.classList.remove('top_nav_css');
                        }
                        if(this.data1 !=undefined){
                          this.data1.classList.remove('open');
                          this.data1.classList.remove('element_css');
                          this.data1.classList.remove('expand_css');
                          this.data1.classList.remove('elementcss');
                          this.data1.classList.remove('expandcss');
                          this.data1.classList.remove('top_nav_css');
                        }
                        if(this.data8 !=undefined){
                          this.data8.classList.remove('open');
                          this.data8.classList.remove('element_css');
                          this.data8.classList.remove('expand_css');
                          this.data8.classList.remove('elementcss');
                          this.data8.classList.remove('expandcss');
                          this.data8.classList.remove('top_nav_css');
                        }
                        this.data7.classList.add('open');
                        this.data7.classList.add('element_css');
                        this.data7.classList.add('expand_css');
                        this.data7.classList.add('elementcss');
                        this.data7.classList.add('expandcss');
                        this.data7.classList.add('top_nav_css');
                      }
              });
              elements2.forEach(elementInner1 =>
                {
                    if(Number(elementInner1['id'])==entityNo && Number(elementInner1['accessKey'])==instanceNo-1)
                        {
                          //console.log("Opening Popup elements2 Left",elementInner1['elementTiming']);
                          this.data8= elementInner1.querySelector('.'+String(elementInner1['elementTiming']));
                          if(this.data2 !=undefined){
                            this.data2.classList.remove('open');
                            this.data2.classList.remove('element_css');
                            this.data2.classList.remove('expand_css');
                            this.data2.classList.remove('elementcss');
                            this.data2.classList.remove('expandcss');
                            this.data2.classList.remove('top_nav_css');
                          }
                          if(this.data3 !=undefined){
                            this.data3.classList.remove('open');
                            this.data3.classList.remove('element_css');
                            this.data3.classList.remove('expand_css');
                            this.data3.classList.remove('elementcss');
                            this.data3.classList.remove('expandcss');
                            this.data3.classList.remove('top_nav_css');
                          }
                          if(this.data4 !=undefined){
                            this.data4.classList.remove('open');
                            this.data4.classList.remove('element_css');
                            this.data4.classList.remove('expand_css');
                            this.data4.classList.remove('elementcss');
                            this.data4.classList.remove('expandcss');
                            this.data4.classList.remove('top_nav_css');
                          }
                          if(this.data5 !=undefined){
                            this.data5.classList.remove('open');
                            this.data5.classList.remove('element_css');
                            this.data5.classList.remove('expand_css');
                            this.data5.classList.remove('elementcss');
                            this.data5.classList.remove('expandcss');
                            this.data5.classList.remove('top_nav_css');
                          }
                          if(this.data6 !=undefined){
                            this.data6.classList.remove('open');
                            this.data6.classList.remove('element_css');
                            this.data6.classList.remove('expand_css');
                            this.data6.classList.remove('elementcss');
                            this.data6.classList.remove('expandcss');
                            this.data6.classList.remove('top_nav_css');
                          }
                          if(this.data7 !=undefined){
                            this.data7.classList.remove('open');
                            this.data7.classList.remove('element_css');
                            this.data7.classList.remove('expand_css');
                            this.data7.classList.remove('elementcss');
                            this.data7.classList.remove('expandcss');
                            this.data7.classList.remove('top_nav_css');
                          }
                          if(this.data1 !=undefined){
                            this.data1.classList.remove('open');
                            this.data1.classList.remove('element_css');
                            this.data1.classList.remove('expand_css');
                            this.data1.classList.remove('elementcss');
                            this.data1.classList.remove('expandcss');
                            this.data1.classList.remove('top_nav_css');
                          }
                          this.data8.classList.add('open');
                          this.data8.classList.add('element_css');
                          this.data8.classList.add('expand_css');
                          this.data8.classList.add('elementcss');
                          this.data8.classList.add('expandcss');
                          this.data8.classList.add('top_nav_css');
                        }
                });
            } // leftClickDetect
          })
          this.entityData.forEach((instance, index) => {
            for (let i = 0; i < this.entityData[index].instances.length; i++) 
            {
                if(!this.popup.instance.AssignmentDone)
                {
                  if(index == Number(element['id']) && i == Number(element['accessKey'])  )
                    {
                      this.popupIdentity=this.popupIdentity+1;
                      this.popup.instance.category = this.entityData[index];
                      this.popup.instance.popupInstanceId = i;
                      this.popup.instance.AssignmentDone=true;
                    }
                } //AssignmentDone
            } // for loop to traverse all entity instances
          });
          this.relationData.forEach((instance, index) => 
          {
            if(!this.popup.instance.relAssignmentDone)
              {
                if( index == Number(element['nonce']) )
                  {
                    this.popup.instance.relCategory = this.relationData[index];
                    this.popup.instance.relAssignmentDone=true;
                    //console.log("Relation Category assigned:",this.popup.instance.relCategory);
                  }
              } // relAssignmentDone
            if(!this.popup.instance.rO_relAssignmentDone)
              {
                if( index == Number(element['lang']) && element['lang']!="")
                  {
                    this.popup.instance.rorelCategory = this.relationData[index];
                    this.popup.instance.rO_relAssignmentDone=true;
                  }
              } // rO_relAssignmentDone
            if(!this.popup.instance.sRO_relAssignmentDone)
              {
                if( index == Number(element['elementTiming']) && element['elementTiming']!="")
                  {
                    this.popup.instance.srorelCategory = this.relationData[index];
                    this.popup.instance.sRO_relAssignmentDone=true;
                  }
              } // sRO_relAssignmentDone

          })// forEach loop to traverse all relations
      }) //elements2 Processing ends 
//--------------------------------------------------------------------------------------------------------
    }) // setTimeout(()
  } // ngAfterViewInit()

  openPopup() {
    // this.popover.isOpen() ? this.popover.close() : this.popover.open();
    // console.log("Popup opener is called");
  }
  getClassName(el:any,overlapyorn:boolean)
    {
      let classNameString:String="";
      if ( el.offsetTop <= 350 && el.offsetLeft > 400)
        {
          classNameString='popup-wrapper1';
        }
        else if ( el.offsetTop <= 350 && el.offsetLeft <= 400 )
        {
          classNameString='popup-wrapper';
        }
        else if (el.offsetTop > 350 && el.offsetLeft > 400 )
        {
            if(overlapyorn)
            {
              classNameString='popup-wrapper4';
            }
            else
            {
              classNameString='popup-wrapper3';
            }
        }
        else if ( el.offsetTop > 350 && el.offsetLeft <= 400 )
        {
          classNameString='popup-wrapper2';
        }
      return String(classNameString) ;
    }
}
